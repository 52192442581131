.logo {
    display: block;
    margin: auto;
    max-width: 50%;
    max-height: 50%;
    min-width: 10%;
    min-height: 10%;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout:none;
}