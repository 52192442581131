
body{
  text-align:center;
  background-color: #fff;
  width:100%;
  height:100%;
  overflow:hidden;
}
a, button, .myelements
{
	-ms-touch-action: manipulation;	/* IE10  */
	touch-action: manipulation;		/* IE11+ */
}
